.name {
  color: #3488bf;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.5px;
}

.achievement {
	color: #fff;
	background-color: #555;
	width: fit-content;
}