@import '../../theme/variables.scss';
@import url('https://fonts.googleapis.com/css?family=Caveat');

.home {
  .banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 150px 0 300px;

    @media screen and (max-width: 480px) {
      /* height: 450px; */
      padding: 80px 0;
    }
  }

  :global(.slick-slider) {
    margin-bottom: 50px;

    :global(.courses-grid) {
      margin-bottom: 10px;
    }
  }

  :global(.cs-column-text) {
    .tagline {
      font-size: 58px !important;
      color: #ffffff !important;
      line-height: 64px !important;
      margin-bottom: 20px;
    }

    .subtag {
      font-size: 30px !important;
      line-height: 42px !important;
      color: #fff !important;
      font-weight: 400 !important;
      margin-bottom: 30px;
      text-transform: none !important;
    }

    .inputBox {
      display: inline-flex;
      height: 72px;
      width: 300px;
      border: 3px solid #fff;
      background-color: transparent;
      color: #fff;
      margin: 20px 20px 20px 20px;
      text-transform: uppercase;
      font-size: 1.5em;
      font-weight: bold;
      letter-spacing: 1px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .inputBox:hover {
      background-position: left bottom;
      text-decoration: none;
      color: rgb(248, 242, 242);
    }

    .searchBtn {
      font-size: 13px;
      font-weight: 700;
      line-height: 19px;
      padding: 16px 20px;
      border-radius: 50px;
      color: #fff;
      text-decoration: none;
      outline: none;
      border: none;
      margin-left: 5px;
    }

    .donation {
      color: #fff;
    }

    :global(.call-actions) {
      padding: 25px 30px;
      margin-top: 20px;

      :global(.cell.icon) {
        @media screen and (max-width: 480px) {
          padding: 0;
        }
      }

      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }
  }

  :global(.cs-section-title) {
    .intro {
      font-size: 16px;
    }

    .viewAll {
      color: #3488bf;
      text-transform: uppercase;
      font-size: 17px;
      display: inline-flex;
      height: 72px;
      width: 150px;
      background-color: #fff;
      border: 3px solid #3488bf;
      margin: 20px 20px 20px 20px;
      font-weight: bold;
      letter-spacing: 1px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .viewAll:hover {
      background-position: left bottom;
      text-decoration: none;
      color: #3488bf;
    }
  }

  :global(.cs-top-categories) {
    img {
      width: 50px;
      height: 50px;
      margin-top: 20px;
    }
  }
}

.getStartedBox {
  display: inline-flex;
  height: 72px;
  width: 300px;
  background-color: #3488bf;
  border: 3px solid #3488bf;
  color: #fff;
  margin: 20px 20px 20px 20px;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: bold;
  letter-spacing: 1px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.getStartedBox:hover {
  background-position: left bottom;
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 375px) {
  .button {
    width: 180px;
  }
}

.partner {
  font-family: 'Caveat';
  font-size: 42px;
  color: #000;
  line-height: 1.3;
}

.partnerImg {
  text-align: center;

  img {
    height: 230px;
  }

  @media only screen and (min-width: 1200px) {
    padding-top: 60px;
  }
}

@media only screen and (min-width: 1200px) {
  .mainButton {
    margin-left: 13%;
  }
}

.news {
  font-size: 28px;
  line-height: 36px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  color: #207dba;

  p {
    font-size: 14px;
    font-weight: normal;
    color: black;
  }
}

.title {
  font-weight: bold;
  font-size: 35px;
  text-transform: uppercase;
  color: #3488bf;
  line-height: 1.2;
}

.morePartners {
  color: #3488bf !important;
  font-size: 18px !important;
  font-weight: bolder;
  letter-spacing: 0.05em;
}